import React from 'react';

const InvestorPage = () => {
    return (

        <section className='InvestorPage' id="InvestorPage">         
            <div className="default_page">
                <h2>Investors Info</h2>

                <div className="investor_content">
                    <h3>Strike Protocol project update</h3>
                    <p>
                        In this update, we are announcing significant changes to the Strike Protocol project,
                        including a simplified in-game economy aimed at facilitating a smoother rollout across a wider gaming audience through gaming distributors.
                    </p>

                    <h3>What is changing?</h3>
                    <p>
                        Due to recent policy changes and direct discussions with Epic Games Store and Steam,
                        we've adjusted our initial game setup to conform to their standards and application procedures for Strike Protocol.

                        <br></br><br></br>

                        Both distributors no longer permit a dual Fiat/Crypto economy system to be launched across their main public storefronts.
                        Consequently, GD Entertainment has removed $STPR cryptocurrency from all Strike Protocol-related purchases and in-game mechanics.

                        <br></br><br></br>

                        These changes left us with two possible routes forward:

                        <ul>
                            <li><b>A.</b> Abandon the large distributor route and release the game as-is across indie platforms.</li>
                            <li><b>B.</b> Adjust the game’s ecosystem to comply with large distributor requirements.</li>
                        </ul>
                    </p>

                    <h3>Choice & Motivation</h3>
                    <p>
                        To support the release and ongoing development of the Strike Protocol, we have decided to pursue the second option, which involves changing to a FIAT-based income model. 
                        We believe this route will allow us to reach a larger volume of players. Additionally, the large distributor route provides an existing and reliable user acquisition channel. 

                        <br></br><br></br>

                        Had we chosen the indie distributor route, we were concerned it might not achieve the critical mass of players needed to sustain a healthy ecosystem. 

                        <br></br><br></br>

                        While this decision removes the $STPR cryptocurrency inflow and in-game activities,
                        it aims to facilitate access to a larger player base through both distributors. We believe this will support the project's overall sustainability.
                    </p>

                    <h3>The subsequent changes in bullet point overview</h3>
                    <p>
                        <ul>
                            <li>$STPR is no longer a Strike Protocol purchasing option.</li>
                            <li>$STPR is no longer a Strike Protocol in-game currency.</li>
                            <li>'Credits' are purchasable across traditional gaming distribution platforms.</li>
                            <li>'Credits' are the new in-game currency.</li>
                            <li>Distributor fees and VAT regulations are upheld according to distribution regulations.</li>
                            <li>GD Entertainment will develop/acquire a standalone 'Credits' to Fiat exchange.</li>
                            <li>The new model enables a partner model with traditional Gaming Publishers.</li>
                            <li>$STPR holders will receive reward pools derived from the FIAT currency instream. </li>
                        </ul>
                    </p>

                    <h3>Embracing the challenge</h3>
                    <p>
                        GD Entertainment is moving forward and aligning with the distributors' guidelines with the intention of benefiting both our investors and players.
                        We have refrained from using $STPR as an in-game currency or purchase method.

                        <br></br><br></br>

                        We have embraced this challenge with the hope that the new requirements will be a positive change, benefiting all investors. 
                        Strike Protocol’s game mode will revert to using the industry-standard hard currency (“credits”) for all in-game actions.
                        Additionally, we aim to sign up a game publisher. 

                        <br></br><br></br>

                        During the redesign of the ecosystem, we focused on maintaining elements that we believe will appeal to FIAT players, potentially leading to the game's popularity.
                        We intend to implement an out-of-game platform to maintain the play-to-earn element without compromising the in-game economy.

                    </p>

                    <h3>Game Publisher Model</h3>
                    <p>
                        By removing the crypto inflow, we hope to gain the advantage of engaging with the gaming publisher model, which was not part of our initial project strategy. GD Entertainment,
                        assisted by our partner Lodestar Group, has initiated negotiations with potential gaming industry publishers. We aspire to make a significant announcement about the selected publisher soon.

                        <br></br><br></br>

                        Adopting this model, we aim to expand our player base more quickly through potential partnerships that may include marketing efforts and resource-sharing initiatives.

                    </p>

                    <h3>Play-to-earn</h3>
                    <p>
                        In the new model, players of Strike Protocol can convert their „credits“ into FIAT, preserving the original play-to-earn element designed to enhance player appreciation.

                        <br></br><br></br>

                        We believe that this model will gain acceptance as it retains much of the original concept's appeal for players.
                        GD Entertainment plans to develop or acquire the additional out-of-game interface for this process.

                        <br></br><br></br>

                        The new interface will be included in our upcoming roadmap. We are currently exploring the possibility of partnering with external providers to meet this
                        additional tech requirement in alignment with its necessity in the ecosystem.
                    </p>

                    <h3>Future Flexibility</h3>
                    <p>
                        Another positive outcome of adapting the Strike Protocol to meet the requests of both major distributors is that GD Entertainment has
                        acknowledged and supported both distributors in their current market environments, creating goodwill on all sides.

                        <br></br><br></br>

                        We understand that they, too, are transitioning from Web2 to Web3, and cycles move slower in their market than we might prefer. In calls and in-person meetings with the distributors,
                        we have assured them that Strike Protocol will be able to adapt to a broader currency system when they decide to make policy changes in the future.

                        <br></br><br></br>

                        <h5>At present, the changes include:</h5>

                        <ul>
                            <li>A simplified, more traditional in-game economy.</li>
                            <li>Clarity for distributors on their income model.</li>
                            <li>Legal compliance with VAT and platform requirements.</li>
                            <li>Flexibility to adapt to future platform rule changes.</li>
                            <li>A straightforward player system with an additional P2E element.</li>
                        </ul>
                    </p>

                    <h3>In-Game Economy Flowchart</h3>
                    <p>
                        Game distribution platforms have changed their rules about games that integrate crypto tokens or NFTs. To align with their requirements, we have updated our flowchart accordingly:
                    </p>
                    <img src={require('../assets/InvestorPage/sp_flowchart.png')} className="invs-img" alt={'Flowchart'} />    
                    <p>Unlike our previous version, this flowchart is specifically designed to meet the requirements of major distribution platforms. </p>

                    <h3>Key Changes:</h3>
                    <p><span className="key-span">Standard Hard Currency:</span> We will use a standard hard currency, referred to in this flowchart as „credits“ (name subject to change),
                        instead of $STPR for all in-game actions.

                        <br></br><br></br>

                        <span className="key-span">FIAT Purchases:</span> Players will purchase „credits“ with FIAT currency from the store. This will be the only stream for FIAT currency,
                        ensuring each platform receives its appropriate share without issues.

                        <br></br><br></br>

                        <span className="key-span">In-Game Economy:</span> All in-game costs and trades will be conducted using „credits“ that have already been purchased with FIAT. This setup aims that distribution platforms receive
                        their fees and countries receive their VAT taxes, eliminating any legal issues related to game costs or actions performed outside the game.

                        <br></br><br></br>

                        <span className="key-span">Adaptability:</span> This system is designed to ensure that if distribution platforms change their rules in the future, „credits“ can be easily converted to $STPR with minimal adjustments to the game mechanics and economy, making the system more adaptable. Additional Feature: In our out-of-game ecosystem, accessible via our webpage, players can exchange their „credits“ back to FIAT at a set rate.
                        This comprehensive approach aligns with distribution platform requirements and provides flexibility and legal compliance, ensuring a smooth and adaptable in-game and out-of-game economy.

                    </p>

                    <h3>Key Changes:</h3>
                    <p>
                        When Strike Protocol operated within the cryptocurrency domain, it was assigned an 18+ rating due to the nature of its content and associated elements. However, with the transition and updates to the game, new, more inclusive ratings have been assigned,
                        allowing a broader audience to enjoy the game. With these changes, our rating in the majority of markets now stands at 12+. 
                    </p>
                    <h5>For instance:</h5>
                    <p>
                        Brazil: The game has received a "Classificação Indicativa: 10 Anos" rating, indicating it is suitable for players aged 10 and above.
                        This rating includes descriptors for violence and interactive elements such as in-game purchases and online interactivity.

                        <br></br><br></br>

                        Germany: The game is rated for ages 12 and above and is categorized under "Gewalt" (violence). Like Brazil, this rating acknowledges the presence of in-game
                        purchases and online interactivity.

                        <br></br><br></br>

                        These updated ratings demonstrate that Strike Protocol is now accessible to younger players in various regions, expanding the potential player base and fostering 
                        a more inclusive gaming environment. 
                    </p>


                    <h3>How does all this translate to our investors?</h3>
                    <p>
                        $STPR holders are not able to use or convert their $STPR into in-game currency/functionality.

                        <br></br><br></br>

                        GD Entertainment believes, however, that our investor base will be pleased with the assurance that we are expanding across a broader
                        audience with large distributor backing while upholding our reward pool model, which has been a solid part of our pre- and post-launch campaigns.

                        <br></br><br></br>

                        By reassuring our distributors of our intent to generate large volume and revenue through FIAT currency, we also reaffirm our commitment to our $STPR investors
                        by ensuring them a fair share of the revenue generated in the form of rewards, leveraging the broader distribution and audience scale.
                    </p>

                    <h3>How are $STPR holder rewards generated?</h3>
                    <p>
                        Players are able to purchase „credits“ across the distributor platforms, from which GD Entertainment receives FIAT payments from the game distributors.

                        <br></br><br></br>

                        A portion of the received payments is allocated to secure sufficient funds for continuous development and to finance the Strike Protocol player ecosystem,
                        while the remaining funds are converted to ETH and deposited into a reward pool.

                        <br></br><br></br>

                        $STPR holders will be eligible to qualify for a share of this reward pool each time GD Entertainment activates a snapshot after contributing ETH funds to the reward pool.

                        <br></br><br></br>

                        To qualify, a holder's balance is measured between two snapshot points. As long as the holder has held $STPR during the time period between these snapshots,
                        they are eligible to claim a portion of the total ETH reward pool through our App.

                        <br></br><br></br>

                        Holders are explicitly not required to stake or lock their $STPR tokens. Rewards are our loyalty mechanic that encourages
                        $STPR holders to voluntarily hold their $STPR between snapshots. 

                    </p>

                    <h3>STPR Reward Distribution Among Token Holders</h3>
                    <img src={require('../assets/InvestorPage/flowchart-hd-bold.png')} className="invs-img" alt={'Flowchart'} /> 
                    <p>
                        In the Strike Protocol ecosystem, STPR generated from transactions within the game will be distributed among investors based on their tier level,
                        determined by their holdings of STPR tokens. The distribution process is as follows:
                    </p>
                    <br></br><br></br>
                    <h5>Overall Allocation:</h5>
                    <p>
                        The total STPR reward pool, excluding GDE's share as a token holder, will be converted from transactions and divided into three distinct portions based on tier levels.
                    </p>
                    <h5>Tier Levels and Distribution:</h5>
                    <ul>
                        <li>Tier 1 Holders:</li>
                        <ul>
                            <li>Eligibility: Investors holding more than 10 million STPR tokens.</li>
                            <li>Share of Pool: 35% of the total STPR reward.</li>
                            <li>Distribution Method: This portion of the STPR reward will be divided equally among all Tier 1 holders.</li>
                        </ul>

                        <li>Tier 2 Holders:</li>
                        <ul>
                            <li>Eligibility: Investors holding between 2 to 10 million STPR tokens.</li>
                            <li>Share of Pool: 35% of the total STPR reward.</li>
                            <li>Distribution Method: This portion of the STPR reward will be divided equally among all Tier 2 holders.</li>
                        </ul>

                        <li>Tier 3 Holders:</li>
                        <ul>
                            <li>Eligibility: Investors holding between 0.5 to 2 million STPR tokens.</li>
                            <li>Share of Pool: 30% of the total STPR reward.</li>
                            <li>Distribution Method: This portion of the STPR reward will be divided equally among all Tier 3 holders.</li>
                        </ul>
                    </ul>
                    <p>
                        By implementing this tier-based distribution model, we hope to maintain a balanced and motivated investor community,
                        ultimately supporting the growth and stability of the Strike Protocol ecosystem.
                    </p>

                    <h3>Reward pool filling</h3>
                    <p>
                        The exact timing, value, and frequency of STPR contributions to the reward pool depend on player adoption of Strike Protocol and usage rates across distributors,
                        as well as payout models from independent distributors.

                        <br></br><br></br>

                        For more information on distributor payments, here are examples of the most common models:
                        <br></br>
                        distribution models:
                        <ul>
                            <li><a href="https://support.google.com/paymentscenter/answer/7159355?hl=en" target="_blank" rel="noreferrer" >https://support.google.com/paymentscenter<br></br>/answer/7159355?hl=en</a></li>
                            <li><a href="https://developer.apple.com/help/app-store-connect/getting-paid/overview-of-receiving-payments/" target="_blank" rel="noreferrer" >https://developer.apple.com/help/app-store-<br></br>connect/getting-paid/overview-of-receiving-payments/</a></li>
                            <li><a href="https://partner.steamgames.com/doc/finance/payments_salesreporting/faq" target="_blank" rel="noreferrer" >https://partner.steamgames.com/doc<br></br>/finance/payments_salesreporting/faq</a></li>
                            <li><a href="https://store.epicgames.com/en-US/distribution" target="_blank" rel="noreferrer" >https://store.epicgames.com<br></br>/en-US/distribution</a></li>
                        </ul>
                    </p>

                    <h3>Marketing actions</h3>
                    <p>
                        GD Entertainment will be launching a comprehensive marketing campaign with the goal of structurally increasing player numbers
                        and ensuring a smooth transition to the new 'Credits' system.

                        <br></br><br></br>

                        This will occur across multiple marketing outlets to promote the game and attract more players, including online advertising,
                        social media engagement, and partnerships with gaming influencers.

                        <br></br><br></br>

                        We have partnered with Hotkey LLC, a company with extensive experience in the gaming industry, so that they can spearhead the marketing campaign,
                        leveraging their expertise to ensure a significant inflow of new players.

                        <br></br><br></br>

                        Additionally, Lodestar Group and a soon-to-be-appointed publisher will coordinate their efforts to effectively incorporate marketing
                        activities and increase user acquisition, thereby boosting revenue.

                        <br></br><br></br>

                        In conclusion, we are implementing these changes with the best intentions and the hope that they will benefit our community, investors, and players.
                        While nothing is certain, we are optimistic that these adjustments will lead to a positive outcome for the Strike Protocol project.

                        <br></br><br></br>

                        Thank you for your understanding and continued support.
                    </p>

                </div>

                <div className="dev-container">
                    <h3>Development roadmap</h3>
                    <h4>June 2024</h4>
                    <ul className="roadmap-list"> 
                        <li className="checked">June 14 - The new production/game website is up</li>
                        <li>June 14 - A new investor page is up</li>
                        <li>June 17 - Lodestar Partnership Announcement</li>
                        <li className="checked"><span className="timeline-bold">June 18 - Teaser of the announcement trailer</span></li>
                        <li>June 19 - Steam game page preview</li>
                        <li>June 20 - Epic game page preview</li>
                        <li className="checked">June 21 - Steam page is in the approval phase</li>
                        <li className="checked">June 24 - Epic page is in the approval phase</li>
                        <li><span className="timeline-bold">June 24 - Product Announce Trailer</span></li>
                        <li>June 26 - Creating Strike Protocol Twitch game page</li>
                        <li>June 27 - Marketing activities for the game trailer by Hotkey</li>
                        <li className="checked"><span className="timeline-bold">June 28 - Steam page is approved and verified by Steam</span></li>
                        <li><span className="timeline-bold">June 28 - Updated Beta Test (Open)</span></li>
                    </ul>

                    <h4>July 2024</h4>
                    <ul className="roadmap-list">
                        <li>July 1 - Marketing starts for Steam page by Hotkey</li>
                        <li>July 2 - Marketing activities by Hotkey for Steam Store</li>
                        <li>July 2 - Early Beta Visuals</li>
                        <li className="checked"><span className="timeline-bold">July 3 - The epic page is approved and verified</span></li>
                        <li>July 3 - Product Screenshots</li>
                        <li>July 3 - Influencers Beta play recap video #1</li>
                        <li>July 3 - Publisher Announcement</li>
                        <li>July 4 - Strike Protocol Instagram</li>
                        <li className="checked"><span className="timeline-bold">July 5 - Show that the Steam Store is live with a link</span></li>
                        <li>July 5 - Marketing srarts for EGS page by Hotkey</li>
                        <li>July 5 - Strike Protocol TikTok</li>
                        <li className="checked"><span className="timeline-bold">July 8 - Show that Epic Games Store is Live with link</span></li>
                        <li>July 9 - Marketing activities by Hotkey for Epic Games Store</li>
                        <li>July 10 - Product Announce Press Release</li>
                        <li>July 11 - Croatian locale news about Strike Protocol</li>
                        <li>July 12 - IGN game announcement/preview</li>
                        <li>July 13 - PC Gamer  magazines game announcement/preview</li>
                        <li>July 15 - Publisher activities for Strike Protocol</li>
                        <li>July 17 - Launch Trailer</li>
                        <li><span className="timeline-bold">July 18 - Announce Game is live on EGS and Steam - Launch Day</span></li>
                        <li>July 18 - Marketing activities for launch Trailer by Hotkey</li>
                        <li>July 18 - Marketing activities for STEAM</li>
                        <li>July 18 - Marketing activities for EGS</li>
                        <li>July 18 - Early Access Bonus: We will offer exclusive<br></br> in-game items or content for players who participate in early access</li>
                        <li><span className="timeline-bold">July 18 - Early Access (Open) - Launch day</span></li>
                        <li>July 22 - Influencers Launch recap video #2</li>
                        <li>July 24 - Game Mobile porting/development Start</li>
                        <li>July 26 - Steam Achievement badges</li>
                    </ul>

                    <h4>TBD - To be announced</h4>
                    <ul className="roadmap-list">
                        <li>TBD - Announce that the game is approved for the Apple App Store</li>
                        <li>TBD - Announce that the game is approved for the Goole plays Store</li>
                    </ul>

                </div>


                <div className="investor_cta">
                    <a href="https://gempad.app/presale/0x3D9029C5D43e178688A40a582223c96B5e391C97?network=Ethereum" target="_blank" rel="noferrer noreferrer" >
                    <button className='investor_btn'>
                        <p>
                            Presale
                        </p>
                    </button>
                    </a>

                    {/*
                    <a href="https://strike-protocol.com/wp-content/uploads/2024/03/PITCH-DECK-Strike-Protocol.pdf" target="_blank" rel="noferrer noreferrer">
                        <button className='investor_btn'>
                            <p>
                                Pitch Deck
                            </p>
                        </button>
                    </a>
                    */}

                    <a href="Strike-Protocol-litepaper.pdf" target="_blank" rel="noferrer noreferrer">
                        <button className='investor_btn'>
                            <p>
                                Litepaper
                            </p>
                        </button>
                    </a>

                    {/*
                    <a href="https://linktr.ee/StrikeProtocol" target="_blank" rel="noferrer noreferrer">
                        <button className='investor_btn'>
                            <p>
                               Linktree
                            </p>
                        </button>
                    </a>
                    */}

                    <a href="https://github.com/solidproof/projects/blob/main/2024/Strike%20Protocol/SmartContract_Audit_Solidproof_StrikeProtocol.pdf" target="_blank" rel="noferrer noreferrer">
                        <button className='investor_btn'>
                            <p>
                               Audit
                            </p>
                        </button>
                    </a>

                    <a href="https://github.com/solidproof/projects/blob/main/2024/GLOBAL%20DODO%20ENTERTAINMENT/KYC_Certificate_GD_ENTERTAINMENT.png" target="_blank" rel="noferrer noreferrer">
                        <button className='investor_btn'>
                            <p>
                                KYC
                            </p>
                        </button>
                    </a>
                </div>

            </div>

        </section>
    )
}

export default InvestorPage